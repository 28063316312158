<template>
    <div>
      <VrImg :list="vrList" />
     <Pagination :page="page" :pageSize="pageSize" :total="total" @handleList="handleList" />
    </div>
</template>

<script>
import VrImg from '../components/vr_img.vue'
import Pagination from '../components/pagination.vue'
import {mapState} from 'vuex'
export default {
    data() {
        return {
            vrList:[],
            total:null,
            page:1,
            pageSize:6
        };
    },
    components:{
        VrImg,
        Pagination
    },
    created() {

    },
    mounted() {
        this.handleImg()
    },
    computed:{
        ...mapState(['place'])
    },
    methods: {
        handleList(e){
            this.page=e
			this.handleImg()
		},
       async handleImg(){
             const res=await this.request('/tree_img/vr',{
               params:{
                   imgType:'VR可视化',
                   page:this.page,
                   pageSize:this.pageSize,
                 domainCode:this.place
               }
           })
           if(res.status==200){
               this.vrList=res.data.list
               this.total=res.data.total
           }

       }
    }
};
</script>

<style scoped lang='less'>

</style>
