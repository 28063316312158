<template>
<div>
    <div class="list">
        <div
            class="item"
          
            v-for="(item,index) in list"
            :key="index"
            :style="{ 'margin-right': (index + 1) % 3 == 0 ? '0' : '' }"
            @click="handlePush(item.url)"
        >
            <div class="img">
                <img :src="'/gsmm/api/v1/'+item.url" alt />
            </div>
            <div class="title">{{item.old_tree_id}}</div>
        </div>
    </div> 
    <No v-if="list.length==0" />
</div>
   
</template>

<script>
import No from './no.vue'
export default {
    props: {
        list: Array
    },
    data() {
        return {
         
        };
    },
        components:{
No
    },
    methods: {
        handlePush(url){
            this.$router.push({
                path:'/vr_detail',
                query:{
                    url
                }
            })
        }
    }
};
</script>

<style scoped lang='less'>
.list {
    width: 100%;
    // background: red;
    display: flex;
    flex-wrap: wrap;
    .item {
        width: 31%;
        height: 300px;
        background: #fff;
        margin-right: 3.5%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding:20px 20px 10px 20px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.2) ;
        .img {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                   -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
            }
                        img:hover {

                   -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
            }
        }
        .title{
            margin-top: 10px;
            color: #666;
        }
    }
}
@media (max-width:1919px){
    .list .item{
        height:230px;
    }
}
@media (max-width:1439px){
    .list .item{
        height:200px;
    }
}
</style>
